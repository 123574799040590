import React, { useState } from "react";
import { LiaMaskSolid } from "react-icons/lia";

export default function Contact() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "6f3dc825-f7d7-4041-a129-1133d8702206");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Dziękujemy! Wiadomość wysłana");
      event.target.reset();
    } else {
      console.log("Wystąpił błąd", data);
      setResult(data.message);
    }
  };

  // Temporary modal RODO
  const [selectedCategory, setSelectedCategory] = useState(null);

  const openModal = (category) => {
    setSelectedCategory(category);
  };

  const closeModal = () => {
    setSelectedCategory(null);
  };
  const iconData = { privacy: <LiaMaskSolid /> };

  return (
    <>
      <div className="form-background">
        <div className="contact-form">
          <h3 className="title">FORMULARZ KONTAKTOWY</h3>
          <form className="form" onSubmit={onSubmit}>
            <input
              type="hidden"
              name="access_key"
              value="6f3dc825-f7d7-4041-a129-1133d8702206"
            />
            <label className="field" htmlFor="name">
              Imię:
            </label>
            <input
              className="input"
              type="text"
              id="name"
              name="name"
              required
            />
            <label className="field" htmlFor="email">
              E-mail:
            </label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              required
            />
            <label className="field" htmlFor="phone">
              Telefon:
            </label>
            <input
              className="input"
              type="tel"
              id="phone"
              name="phone"
              required
            />
            <label className="field" htmlFor="message">
              Wiadomość:
            </label>
            <textarea
              className="input message"
              id="message"
              name="message"
              required
            ></textarea>
            <label className="field agreement" htmlFor="agreement">
              <input id="agreement" type="checkbox" name="agreement" required />
              <span className="required">*</span>
              Zgadzam się na przetwarzanie moich
              {Object.keys(iconData).map((category, index) => (
                <button
                  key={category}
                  className="rodo"
                  aria-label="Otwórz informacje RODO"
                  onClick={() => openModal(category)}
                >
                  danych osobowych
                </button>
              ))}
            </label>
            <button className="send" type="submit">
              Wyślij
            </button>
          </form>
          <span>{result}</span>
        </div>
      </div>
      {selectedCategory && (
        <div className="modal">
          <h4>Polityka firmy</h4>
          <button className="close" onClick={closeModal}>
            x
          </button>
          <article className={`policy ${selectedCategory}`}>
            <h5>
              Klauzula informacyjna RODO w zakresie przetwarzania danych
              osobowych
            </h5>
            <p>
              1. Administratorem danych osobowych jest firma RICE. NIP
              8481875028
              <br />
              2. Administrator wyznaczył inspektora ochrony danych osobowych.
              Dane kontaktowe inspektora: Oleksandr Boiko, e-mail:
              alex1094143@gmail.com
              <br />
              3. Przekazane dane osobowe przetwarzane będą w celu realizacji
              usług, obsługi zgłoszeń i udzielania odpowiedzi na zgłoszenia.
              <br />
              4. Kategorie danych osobowych obejmują m.in. imię i nazwisko,
              numer telefonu, adres e-mail, adres, dane dedykowane do
              procesu/usługi/projektu.
              <br />
              5. Pani / Pana dane osobowe mogą być przekazywane podmiotom
              przetwarzającym dane osobowe na zlecenie administratora: dostawcy
              usług IT.
              <br />
              6. Państwa dane osobowe będą przechowywane przez okres istnienia
              prawnie uzasadnionego interesu administratora, chyba że Pani / Pan
              wyrazi sprzeciw wobec przetwarzania danych.
              <br />
              7. Państwa dane nie będą przekazywane do państwa trzeciego ani
              organizacji międzynarodowej.
              <br />
              8. Posiadają Państwo prawo dostępu do treści swoich danych oraz
              prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,
              prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do
              cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z
              prawem przetwarzania, którego dokonano na podstawie zgody przed
              jej cofnięciem.
              <br />
              9. Mają Państwo prawo wniesienia skargi do organu nadzorczego
              zajmującego się ochroną danych osobowych, którym jest Prezes
              Urzędu Ochrony Danych Osobowych, gdy uznają Państwo, iż
              przetwarzanie Państwa danych osobowych narusza przepisy ustawy z
              dnia 10 maja 2018 r. o ochronie danych osobowych (tekst jednolity
              Dz. U. z 2018 r., poz. 1000) lub przepisy Rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
              2016 r. w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
              rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r.
              (Dz.Urz.UE.L Nr 119, str. 1).
              <br />
              10. Dane udostępnione przez Panią/Pana nie będą podlegały
              zautomatyzowanemu podejmowaniu decyzji oraz profilowaniu.
              <br />
              11. Dane pochodzą od osób, których dane dotyczą.
              <br />
              12. Podanie przez Państwa danych osobowych jest dobrowolne.
            </p>
          </article>
        </div>
      )}
    </>
  );
}
