import React from "react";
import { useSpring, animated } from "react-spring";

import "./aida.scss";
import "./process.scss";
import "./technologies.scss";
import "./trends.scss";
import "./couple.scss";
import "./ppc.scss";
import "./seo.scss";
import "./social.scss";
import "./email.scss";
import "./content.scss";
import "./offerings.scss";
import "./advantages.scss";

const AIDA = ({ data }) => {
  const { aida } = data;

  const titleSpring = useSpring({
    from: { translateY: -100, opacity: 0 },
    to: { translateY: 0, opacity: 1 },
    delay: 333,
    config: { mass: 1.5, tension: 300, friction: 20, delay: 300 },
  });

  const backgroundSpring = useSpring({
    from: { translateY: -100, opacity: 0 },
    to: { translateY: 0, opacity: 1 },
    config: { mass: 2.2, tension: 300, friction: 20 },
  });

  return (
    <section className="aida">
      <animated.div className="background" style={backgroundSpring}>
        <animated.span className="page-title" style={titleSpring}>
          {aida.title}
        </animated.span>
      </animated.div>
      {Object.keys(aida.container).map((index) => (
        <div className="content-container" key={index + 1}>
          <video className="vid" autoPlay muted loop>
            <source
              src={`vid/${aida.container[index].src}`}
              alt={aida.container[index].alt}
            />
          </video>
          <div className="mask"></div>
          <p className="text">{aida.container[index].text}</p>
        </div>
      ))}
    </section>
  );
};

const Article = ({ data, articleKey }) => {
  const { article } = data;
  const currentArticle = article[articleKey];

  return (
    <article className={`article ${articleKey}`}>
      <div className="title-container">
        <h1 className="title">{currentArticle.title}</h1>
        <img
          className="img"
          src={`img/services/article/${currentArticle.src}`}
          alt={currentArticle.alt}
        />
      </div>
      <div className="content-container">
        <p className="intro">{currentArticle.text}</p>
        {Object.keys(currentArticle.container).map((index) => (
          <div className="sub-container" key={index + 1}>
            <h2 className="sub-title">
              {currentArticle.container[index].title}
            </h2>
            <p className="sub-text">{currentArticle.container[index].text}</p>
          </div>
        ))}
        <p className="ending">{currentArticle.subText}</p>
      </div>
    </article>
  );
};

const List = ({ data }) => {
  const { list } = data;
  return (
    <article className="list">
      <h1 className="title">{list.title}</h1>
      <h2 className="sub-title">{list.subTitle}</h2>
      <h3 className="topic-title">{list.topicTitle}</h3>
      {Object.keys(list.topicContainer).map((index) => (
        <div key={index + 1}>
          <li className="list">{list.topicContainer[index]}</li>
        </div>
      ))}
      <h3 className="benefits-title">{list.benefitsTitle}</h3>
      {Object.keys(list.benefitsContainer).map((index) => (
        <div key={index + 1}>
          <li className="list">{list.benefitsContainer[index]}</li>
        </div>
      ))}
      {Object.keys(list.text).map((index) => (
        <div key={index + 1}>
          <p className="ending">{list.text[index]}</p>
        </div>
      ))}
    </article>
  );
};

const Couple = ({ data }) => {
  const { couple } = data;
  return (
    <article className="couple">
      <h1 className="title">{couple.title}</h1>
      {Object.keys(couple.container).map((index) => (
        <div className="content-container" key={index + 1}>
          <h2 className="sub-title">{couple.container[index].title}</h2>
          <p className="first">
            <u className="man">{couple.man}</u>
            {couple.container[index].first}
          </p>
          <p className="second">
            <u className="woman">{couple.woman}</u>
            {couple.container[index].second}
          </p>
        </div>
      ))}
      <p className="ending">{couple.text}</p>
    </article>
  );
};

export { AIDA, Article, List, Couple };
