import React, { useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import "./style.scss";

const Diagram = ({ data }) => {
  const { diagram } = data;

  return (
    <section className="diagram">
      <div className="circle n1"></div>
      <div className="circle n2"></div>
      <div className="circle n3"></div>
      {Object.keys(diagram).map((index) => (
        <span className={`parts ${diagram[index].class}`}>
          {diagram[index].text}
        </span>
      ))}
    </section>
  );
};

const Prices = ({ data }) => {
  const { prices } = data;
  const [category, setCategory] = useState(1);
  const categoryData = prices.categories[category];
  const buttonRefs = useRef([]);

  const updateButtonRef = (catKey, ref) => {
    buttonRefs.current[catKey] = ref;
  };

  function getActivePosition() {
    const activeButton = buttonRefs.current[category];
    if (activeButton) {
      const containerWidth = activeButton.parentNode.offsetWidth;
      const buttonWidth = activeButton.offsetWidth;
      const offsetLeft = activeButton.offsetLeft;
      return offsetLeft + (buttonWidth - containerWidth) / 2;
    }
    return 0;
  }

  function getActiveButtonWidth() {
    const activeButton = buttonRefs.current[category];
    return activeButton ? activeButton.offsetWidth : 0;
  }

  const activeSpring = useSpring({
    transform: `translateX(${getActivePosition()}px)`,
    width: `${getActiveButtonWidth()}px`,
    config: { tension: 300, friction: 15 },
  });

  return (
    <>
      <div className="table-switcher">
        {Object.keys(prices.categories).map((catKey) => (
          <Button
            className="button"
            key={catKey}
            ref={(ref) => updateButtonRef(catKey, ref)}
            onClick={() => setCategory(parseInt(catKey))}
          >
            {prices.categories[catKey].name}
          </Button>
        ))}
        <animated.div className="active" style={activeSpring}></animated.div>
      </div>
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow className="row head">
              {prices.title &&
                Object.values(prices.title).map((title) => (
                  <TableCell className="text" key={title}>
                    {title}:
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryData &&
              Object.keys(categoryData.container).map((containerKey) => (
                <TableRow className="row body" key={containerKey}>
                  <TableCell className="price">
                    {categoryData.container[containerKey].price}
                  </TableCell>
                  <TableCell>
                    <ul className="profit">
                      {Object.values(
                        categoryData.container[containerKey].description
                      ).map((desc, index) => (
                        <li key={index}>{desc}</li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className="star-text">
        {prices.starText1}
        <br />
        {prices.starText2}
      </p>
    </>
  );
};

export { Diagram, Prices };
