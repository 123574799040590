import React from "react";

import "./style.scss";

const Info = ({ data }) => {
  const { info } = data;
  return (
    <article className="info">
      <div className="intro">
        <p className="text">{info.title}</p>
        <img className="image" src={info.src} alt={info.alt} />
      </div>
      {Object.keys(info.container).map((index) => (
        <div className="container" key={index + 1}>
          <h2 className="sub-title">{info.container[index].title}</h2>
          <p className="sub-text">{info.container[index].text}</p>
          <i className="quote">{info.container[index].quote}</i>
        </div>
      ))}
    </article>
  );
};

const Goals = ({ data }) => {
  const { goals } = data;
  return (
    <article className="goals">
      <div className="intro">
        <p className="text">{goals.title}</p>
        <img className="image" src={goals.src} alt={goals.alt} />
      </div>
      {Object.keys(goals.container).map((index) => (
        <div className="container" key={index + 1}>
          <h2 className="sub-title">{goals.container[index].title}</h2>
          <p className="sub-text">{goals.container[index].text}</p>
        </div>
      ))}
    </article>
  );
};

export { Info, Goals };
