import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { animated, useSpring, useTrail } from "react-spring";
import { FaMoneyCheckAlt, FaInfo } from "react-icons/fa";
import { MdOutlinePhoneAndroid, MdMarkEmailRead } from "react-icons/md";
import {
  FaGlobe,
  FaMoneyBillTrendUp,
  FaPaintbrush,
  FaPersonCirclePlus,
  FaPersonCircleQuestion,
} from "react-icons/fa6";

import "./style.scss";

const Header = ({ data }) => {
  const [isActivatorActive, setIsActivatorActive] = useState(false);

  const Logo = () => {
    useEffect(() => {
      if (isActivatorActive) {
        setIsActivatorActive(false);
      }
    }, []);

    const [text, setText] = useState("Rob-Int-Com-Ent-");

    useEffect(() => {
      const interval = setInterval(() => {
        setText((prevText) => {
          return prevText.substring(1) + prevText[0];
        });
      }, 200);

      return () => clearInterval(interval);
    }, []);

    return (
      <section className="company">
        <Link to="/" onClick={setIsActivatorActive}>
          <img className="logo" src="svg/company_logo.svg" alt="Logo firmy" />
        </Link>
        <h1 className="name">
          <span className="R">R</span>
          <span className="I">I</span>
          <span className="C">C</span>
          <span className="E">E</span>
        </h1>
        <p className="code">{text}</p>
      </section>
    );
  };

  const Navigation = () => {
    const { link } = data;

    const items = [
      { icon: <FaMoneyCheckAlt />, to: "/price_list" },
      { icon: <FaInfo />, to: "/about" },
      { icon: <FaGlobe />, to: "/webapp" },
      { icon: <FaMoneyBillTrendUp />, to: "/marketing" },
      { icon: <FaPaintbrush />, to: "/graphics" },
      { icon: <FaPersonCirclePlus />, to: "/outsourcing" },
      { icon: <FaPersonCircleQuestion />, to: "/support" },
    ];

    const [isPortrait, setIsPortrait] = useState(
      window.innerHeight > window.innerWidth
    );
    const [isActive, setIsActive] = useState(false);
    const toggleNavigation = () => {
      setIsActive(!isActive);
      setIsActivatorActive(false);
    };

    const currentLocation = useLocation();

    useEffect(() => {
      const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);
        setIsActive(false);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const lineDown = useSpring({
      from: { transform: "rotate(0deg)" },
      to: { transform: isActive ? "rotate(45deg)" : "rotate(0deg)" },
    });

    const lineLeft = useSpring({
      from: { opacity: 1, transform: "translateX(0%)" },
      to: {
        opacity: isActive ? 0 : 1,
        transform: isActive ? "translateX(-100%)" : "translateX(0%)",
      },
    });

    const lineUp = useSpring({
      from: { transform: "rotate(0deg)" },
      to: { transform: isActive ? "rotate(-45deg)" : "rotate(0deg)" },
    });

    const itemsTrailPortrait = useTrail(items.length, {
      transform:
        isActive && isPortrait ? "translateX(-4%)" : "translateX(105%)",
      config: { duration: 140 },
    });

    const itemsTrailLandscape = useTrail(items.length, {
      transform: isActive && isPortrait ? "translateY(0%)" : "translateY(10%)",
      config: { mass: 1.5, tension: 300, friction: 20 },
    });

    const itemsTrail = isPortrait ? itemsTrailPortrait : itemsTrailLandscape;

    return (
      <>
        <div className="activator" onClick={toggleNavigation}>
          <animated.span className="line" style={lineDown}></animated.span>
          <animated.span className="line" style={lineLeft}></animated.span>
          <animated.span className="line" style={lineUp}></animated.span>
        </div>
        <nav
          className={`navigation ${isActive ? "active" : ""}`}
          styles={itemsTrailPortrait}
        >
          {itemsTrail.map((props, index) => (
            <animated.button
              className="button"
              aria-label={`Link do ${items[index].title}`}
              style={props}
              key={index}
            >
              <Link
                className="link"
                to={items[index].to}
                onClick={() => setIsActive(false)}
              >
                {currentLocation.pathname === items[index].to ? (
                  <span className="icon">{items[index].icon}</span>
                ) : (
                  <span className="letters">{link[index + 1]}</span>
                )}
              </Link>
            </animated.button>
          ))}
        </nav>
      </>
    );
  };

  const ContactInfo = () => {
    return (
      <div className="contact-info">
        <Link className="link" to="mailto:infocoderice@gmail.com">
          <span className="email">
            <MdMarkEmailRead />
          </span>
          infocoderice@gmail.com
        </Link>
        <Link className="link" to="tel:537735918">
          <span className="phone">
            <MdOutlinePhoneAndroid />
          </span>
          537735918
        </Link>
      </div>
    );
  };

  const Language = () => {
    return <></>;
  };

  const Theme = () => {
    return <></>;
  };

  return (
    <header>
      <Logo />
      <ContactInfo />
      <Language />
      <Theme />
      <Navigation isActivatorActive={isActivatorActive} />
    </header>
  );
};

export default Header;
