import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import { Info, Goals } from "./components/about/layout.jsx";
import { Items } from "./components/footer/layout.jsx";
import Header from "./components/header/layout.jsx";
import Home from "./components/home/layout.jsx";
import { Diagram, Prices } from "./components/price_list/layout.jsx";
import { AIDA, Article, Couple } from "./components/services/layout.jsx";

import dataHeader from "./data/header.json";
import dataHome from "./data/home.json";
import dataPriceList from "./data/price_list.json";
import dataWebapp from "./data/webapp.json";
import dataMarketing from "./data/marketing.json";
import dataGraphics from "./data/graphics.json";
import dataOutsourcing from "./data/outsourcing.json";
import dataSupport from "./data/support.json";
import dataAbout from "./data/about.json";
import dataFooter from "./data/footer.json";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const PriceList = () => {
  return (
    <>
      <main>
        <Diagram data={dataPriceList} />
        <Prices data={dataPriceList} />
      </main>
    </>
  );
};

const About = () => {
  return (
    <main className="about">
      <Info data={dataAbout} />
      <Goals data={dataAbout} />
    </main>
  );
};

const Webapp = () => {
  return (
    <main className="webapp">
      <AIDA data={dataWebapp} />
      <Article data={dataWebapp} articleKey="process" />
      <Article data={dataWebapp} articleKey="technologies" />
      <article className="audit">
        <h3 className="title">JAKOŚĆ POTWIERDZONA ALGORYTMAMI</h3>
        <img
          className="img"
          src="img/companies/lighthouse.png"
          alt="Idealny wynik audytu"
        />
        <p className="text">
          Każdy klient ma możliwość sprawdzenia jakości dowolnej strony
          internetowej za pomocą narzędzia Lighthouse. To narzędzie rozwijane
          przez Google, analizuje różne aspekty strony, takie jak wydajność,
          dostępność, SEO i inne, przynosząc pełny raport z ocenami i
          zaleceniami.
          <br />
          <br />
          Co do audytu, który mamy, to jak na obrazku przedstawiamy całościową
          ocenę jakości kodu strony internetowej. Nasz audyt obejmuje wszelkie
          kluczowe elementy, które wpływają na widoczność i wydajność strony. To
          oznacza, że Nasz kod jest wysokiej jakości, optymalizowany pod kątem
          SEO i szybkości ładowania.
          <br />
          <br />
          Mając wynik powyżej 90 na wszystkie kategorie w Lighthouse, możemy
          śmiało powiedzieć, że Nasza strona jest na bardzo wysokim poziomie.
          Ten wynik stanowi solidne potwierdzenie jakości Naszego kodu i
          podejścia do projektowania stron internetowych.
        </p>
      </article>
    </main>
  );
};

const Marketing = () => {
  return (
    <main className="marketing">
      <AIDA data={dataMarketing} />
      <Couple data={dataMarketing} />
      <Article data={dataMarketing} articleKey="ppc" />
      <Article data={dataMarketing} articleKey="seo" />
      <Article data={dataMarketing} articleKey="social" />
      <Article data={dataMarketing} articleKey="email" />
      <Article data={dataMarketing} articleKey="content" />
    </main>
  );
};

const Graphics = () => {
  return (
    <main className="graphics">
      <AIDA data={dataGraphics} />
      <Article data={dataGraphics} articleKey="process" />
      <Article data={dataGraphics} articleKey="technologies" />
      <Article data={dataGraphics} articleKey="trends" />
    </main>
  );
};

const Outsourcing = () => {
  return (
    <main className="outsourcing">
      <AIDA data={dataOutsourcing} />
      <Article data={dataOutsourcing} articleKey="process" />
      <Article data={dataOutsourcing} articleKey="offerings" />
      <Article data={dataOutsourcing} articleKey="advantages" />
    </main>
  );
};

const Support = () => {
  return (
    <main className="support">
      <AIDA data={dataSupport} />
      <Article data={dataSupport} articleKey="technologies" />
      <Article data={dataSupport} articleKey="advantages" />
      <Couple data={dataSupport} />
    </main>
  );
};

const Footer = () => {
  return (
    <footer>
      <Items data={dataFooter} />
    </footer>
  );
};

const NotFound = () => {
  return <strong>404 Not Found</strong>;
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Header data={dataHeader} />
    <Routes>
      <Route path="/" element={<Home data={dataHome} />} />
      <Route path="/about" element={<About />} />
      <Route path="/price_list" element={<PriceList />} />
      <Route path="/webapp" element={<Webapp />} />
      <Route path="/marketing" element={<Marketing />} />
      <Route path="/graphics" element={<Graphics />} />
      <Route path="/outsourcing" element={<Outsourcing />} />
      <Route path="/support" element={<Support />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
