import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animated } from "react-spring";
import { FaAnglesRight } from "react-icons/fa6";
import Carousel from "nuka-carousel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";

import { IoStar } from "react-icons/io5";
import { MdOutlineDoubleArrow } from "react-icons/md";
import "./style.scss";
import Contact from "./contact.jsx";

const Home = ({ data }) => {
  const SlideShow = () => {
    const { slideShow } = data;
    const slides = Object.values(slideShow.slides);
    const [activeSlideIndex, setActiveSlideIndex] = useState(null);
    const [transforms, setTransforms] = useState({});

    const handleMouseMove = (e, index) => {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setActiveSlideIndex(index);
      updateTransform(index, x, y, 100, 100);
    };

    const handleMouseLeave = () => {
      setActiveSlideIndex(null);
    };

    const calcTransform = (x, y, cardWidth, cardHeight) => {
      const speedX = 0.1;
      const speedY = 0.1;
      const centerX = cardWidth / 1;
      const centerY = cardHeight / 1;
      const deltaX = x - centerX;
      const deltaY = y - centerY;
      const angleX = (deltaY / centerY) * speedX * 180;
      const angleY = (deltaX / centerX) * speedY * 180;

      return `perspective(20vw) rotateX(${angleX}deg) rotateY(${angleY}deg)`;
    };

    const updateTransform = (index, x, y, width, height) => {
      const transform = calcTransform(x, y, width, height);
      setTransforms((prevTransforms) => ({
        ...prevTransforms,
        [index]: transform,
      }));
    };

    return (
      <>
        <h3 className="checkout">{slideShow.title}</h3>
        <Carousel
          renderTopCenterControls={({ currentSlide, goToSlide }) => (
            <div className="indicators">
              {slides.map((slide, index) => (
                <div
                  className="button"
                  key={index}
                  onClick={() => goToSlide(index)}
                  onMouseMove={(e) => {
                    handleMouseMove(e, index);
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  {currentSlide === index ? (
                    <h1 className="title">{slide.title}</h1>
                  ) : (
                    <animated.img
                      className="background"
                      src={`img/home/${slide.indicatorSrc}`}
                      alt={slide.indicatorAlt}
                      onMouseMove={(e) => {
                        handleMouseMove(e, index);
                      }}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        transform:
                          activeSlideIndex === index
                            ? transforms[index]
                            : "none",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          {slides.map((slide, index) => (
            <div className="slide" key={index + 1}>
              <p className="text">{slide.text}</p>
              <Link className="link" to={slide.to}>
                <p className="to">
                  {slideShow.linkText}
                  <span className="icon">
                    <FaAnglesRight />
                  </span>
                  <img
                    className="image"
                    src={`img/home/${slide.buttonSrc}`}
                    alt={slide.buttonAlt}
                  />
                </p>
              </Link>
            </div>
          ))}
        </Carousel>
      </>
    );
  };

  const ContactForm = () => {
    const { formik } = data;
    const [csrfToken, setCsrfToken] = useState(null);
    const [submissionError, setSubmissionError] = useState(null);

    useEffect(() => {
      const fetchCsrfToken = async () => {
        try {
          const response = await axios.get(
            "http://localhost:8000/messaging/get_csrf_token/"
          );
          setCsrfToken(response.data.csrfToken);
        } catch (error) {}
      };

      fetchCsrfToken();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          "http://localhost:8000/messaging/api/contactform/",
          values,
          {
            headers: {
              "X-CSRFToken": csrfToken,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Помилка відправлення:", error);
        setSubmissionError(
          "Сталася помилка під час відправлення форми. Будь ласка, спробуйте ще раз."
        );
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <section className="contact_form">
        <h2 className="title">
          {formik.form.title}
          <u>{formik.form.titleU}</u>
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            topic: "",
            message: "",
            agreement: false,
          }}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {};
            if (formik.error) {
              if (!values.name) {
                errors.name = formik.error.requiredField;
              }

              if (!values.email) {
                errors.email = formik.error.requiredField;
              } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
                errors.email = formik.error.wrongEmail;
              }

              if (!values.phone) {
                errors.phone = formik.error.requiredField;
              } else if (values.phone.length !== 14) {
                errors.phone = formik.error.wrongPhone;
              }

              if (!values.message) {
                errors.message = formik.error.requiredField;
              } else if (values.message.length < 40) {
                errors.message = formik.error.wrongMessage;
              }

              if (!values.agreement) {
                errors.agreement = formik.error.requiredField;
              }
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <input
                type="hidden"
                name="csrfmiddlewaretoken"
                value="{{ csrf_token }}"
              />
              <label className="field" htmlFor="name">
                {formik.form.name}
              </label>
              <Field className="input data" id="name" type="text" name="name" />
              <ErrorMessage name="name" component="span" />
              <label className="field" htmlFor="email">
                {formik.form.mail}
              </label>
              <Field
                className="input data"
                id="email"
                type="email"
                name="email"
              />
              <ErrorMessage name="email" component="span" />
              <label className="field" htmlFor="phone">
                {formik.form.phone}
              </label>
              <Field
                className="input data"
                id="phone"
                type="tel"
                name="phone"
              />
              <ErrorMessage name="phone" component="span" />
              <label className="field" htmlFor="topic">
                {formik.form.topic}
              </label>
              <Field as="select" className="topic" id="topic" name="topic">
                <option className="input" value="" disabled>
                  {formik.form.option}
                </option>
                {Object.entries(formik.form.container).map(([key, value]) => (
                  <option className="input" key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="topic" component="span" />
              <label className="field" htmlFor="message">
                {formik.form.message}
              </label>
              <Field
                className="input message"
                id="message"
                name="message"
                as="textarea"
              />
              <ErrorMessage name="message" component="span" />
              <label className="field agreement" htmlFor="agreement">
                <Field id="agreement" type="checkbox" name="agreement" />
                <span className="required">*</span>
                {formik.form.agreement}
              </label>
              <ErrorMessage name="agreement" component="span" />
              <button
                className="send"
                type="submit"
                aria-label={formik.form.send}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Відправка..." : formik.form.send}
              </button>
              {submissionError && (
                <span className="error">{submissionError}</span>
              )}
            </Form>
          )}
        </Formik>
      </section>
    );
  };

  const Opinion = () => {
    const { opinion } = data;

    return (
      <section className="opinion">
        <span className="icon">
          <MdOutlineDoubleArrow />
        </span>
        <h1 className="title">{opinion.title}</h1>
        <Carousel
          autoplay
          autoplayInterval={10000}
          wrapAround
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={null}
        >
          {Object.keys(opinion.container).map((index) => (
            <div className="container" key={index + 1}>
              <h2 className="name">{opinion.container[index].name}</h2>
              <span className="star">
                <IoStar /> <IoStar /> <IoStar /> <IoStar /> <IoStar />
              </span>
              <p className="comment">{opinion.container[index].comment}</p>
            </div>
          ))}
        </Carousel>
      </section>
    );
  };

  return (
    <main className="home">
      <video className="vid" autoPlay muted loop>
        <source src="vid/logo-animation.webm" type="video/webm" />
        Twoja przeglądarka nie odtwarza video
      </video>
      <SlideShow />
      <Opinion />
      <div className="motivation">
        <p className="text">
          Czy chcesz dołączyć do grona zadowolonych klientów, których opinie
          widzisz powyżej? <br />
          <u>SKONTAKTUJ SIĘ Z NAMI</u>, <br />a My pomożemy Ci osiągnąć Twoje
          cele technologiczne!
        </p>
      </div>
      <Contact />
    </main>
  );
};

export default Home;
