import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TiArrowUnsorted } from "react-icons/ti";
import { PiArrowSquareInBold } from "react-icons/pi";
import {
  LiaCookieBiteSolid,
  LiaHandshakeSolid,
  LiaMaskSolid,
} from "react-icons/lia";

import "./style.scss";

const Items = () => {
  const [arrowOpacity, setArrowOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      const scrollPosition = window.scrollY;
      const position =
        (documentHeight - scrollPosition - windowHeight) / windowHeight;

      setArrowOpacity(Math.max(0, position));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <span className="arrow" href="#content" style={{ opacity: arrowOpacity }}>
        <TiArrowUnsorted />
      </span>
      <section className="content" id="content">
        <div className="company">
          <h3 className="title">Dane firmy:</h3>
          <p className="text">
            NIP: 9691666521
            <br />
            KRS: 0001117280
            <br />
            REGON: 529190761
          </p>
        </div>
        <nav className="services">
          <h3 className="title">Nasze usługi:</h3>
          <Link
            className="link"
            to="/webapp"
            name="link"
            aria-label="Przejdź do Aplikacji internetowych"
          >
            Aplikacje
          </Link>
          <Link
            className="link"
            to="/outsourcing"
            name="link"
            aria-label="Przejdź do Outsourcing"
          >
            Outsourcing
          </Link>
          <Link
            className="link"
            to="/marketing"
            name="link"
            aria-label="Przejdź do Marketing"
          >
            Marketing
          </Link>
          <Link
            className="link"
            to="/graphics"
            name="link"
            aria-label="Przejdź do Grafiki Komputerowej"
          >
            Grafika
          </Link>
          <Link
            className="link"
            to="/support"
            name="link"
            aria-label="Przejdź do Wsparcie Biznesu"
          >
            Wsparcie
          </Link>
        </nav>
        <p className="copyright">
          &copy; RICE
          <img
            className="image"
            src="img/companies/company-logo.png"
            alt="Logo firmy w clasycznych kolorach"
          />
          2024
          <br />
          Wszelkie prawa zastrzeżone
        </p>
      </section>
    </>
  );
};

const Policy = ({ data }) => {
  const { policy } = data;
  const [selectedCategory, setSelectedCategory] = useState(null);

  const openModal = (category) => {
    setSelectedCategory(category);
  };

  const closeModal = () => {
    setSelectedCategory(null);
  };

  const iconData = {
    cookies: <LiaCookieBiteSolid />,
    rules: <LiaHandshakeSolid />,
    privacy: <LiaMaskSolid />,
  };

  return (
    <>
      <div className="drawer">
        <div className="buttons">
          {Object.keys(iconData).map((category, index) => (
            <button
              key={index}
              className={`button ${policy.class[category]}`}
              aria-label={policy.aria}
              onClick={() => openModal(category)}
            >
              <span>{iconData[category]}</span>
            </button>
          ))}
        </div>
        <button className="open_drawer" aria-label={policy.aria}>
          <PiArrowSquareInBold />
        </button>
      </div>
      {selectedCategory && (
        <div className="modal">
          <h4>Polityka firmy</h4>
          <button className="close" onClick={closeModal}>
            x
          </button>
          <article className={`policy ${policy.class[selectedCategory]}`}>
            <h5>
              Klauzula informacyjna RODO w zakresie przetwarzania danych
              osobowych
            </h5>
            <p>
              1. Administratorem danych osobowych jest firma RICE. NIP
              8481875028
              <br />
              2. Administrator wyznaczył inspektora ochrony danych osobowych.
              Dane kontaktowe inspektora: Oleksandr Boiko tel.: 537 735 918
              e-mail: alex1094143@gmail.com
              <br />
              3. Przekazane dane osobowe przetwarzane będą w celu realizacji
              usług, obsługi zgłoszeń i udzielania odpowiedzi na zgłoszenia;
              <br />
              4. Kategorie danych osobowych obejmują m.in. imię i nazwisko,
              numer telefonu, adres e-mail, adres, dane dedykowane do
              procesu/usługi/projektu;
              <br />
              5. Pani / Pana dane osobowe mogą być przekazywane podmiotom
              przetwarzającym dane osobowe na zlecenie administratora: dostawcy
              usług IT;
              <br />
              6. Państwa dane osobowe będą przechowywane przez okres istnienia
              prawnie uzasadnionego interesu administratora, chyba że Pani / Pan
              wyrazi sprzeciw wobec przetwarzania danych;
              <br />
              7. Państwa dane nie będą przekazywane do państwa trzeciego ani
              organizacji międzynarodowej;
              <br />
              8. Posiadają Państwo prawo dostępu do treści swoich danych oraz
              prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,
              prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do
              cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z
              prawem przetwarzania, którego dokonano na podstawie zgody przed
              jej cofnięciem;
              <br />
              9. Mają Państwo prawo wniesienia skargi do organu nadzorczego
              zajmującego się ochroną danych osobowych, którym jest Prezes
              Urzędu Ochrony Danych Osobowych, gdy uznają Państwo, iż
              przetwarzanie Państwa danych osobowych narusza przepisy ustawy z
              dnia 10 maja 2018 r. o ochronie danych osobowych (tekst jednolity
              Dz. U. z 2018 r., poz. 1000) lub przepisy Rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
              2016 r. w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
              rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r.
              (Dz.Urz.UE.L Nr 119, str. 1);
              <br />
              10. Dane udostępnione przez Panią/Pana nie będą podlegały
              zautomatyzowanemu podejmowaniu decyzji oraz profilowaniu;
              <br />
              11. Dane pochodzą od osób, których dane dotyczą;
              <br />
              12. Podanie przez Państwa danych osobowych jest dobrowolne;
            </p>
          </article>
          <button className="accept" onClick={closeModal}>
            Akceptuje
          </button>
        </div>
      )}
    </>
  );
};

export { Items, Policy };
